<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ laboralDedication.dedicacionLaboralId == 0 ? $t('Routes.LaboralDedicationAdd'): $t('Routes.LaboralDedicationEdit') }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col
            cols="12"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.laboralDedication.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.laboralDedication.columns.name')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="laboralDedication.nombre"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col
            cols="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.laboralDedication.columns.description')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-textarea
                  v-model="laboralDedication.descripcion"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
            <b-spinner v-if="addingLaboralDedication" small class="mr-1" />
            {{ laboralDedication.dedicacionLaboralId == 0 ? $t('Lists.Add'): $t('Lists.Edit') }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalCall: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const addingLaboralDedication = ref(false)
    const laboralDedication = ref({
      dedicacionLaboralId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    })

    const blankLaboralDedication = {
      dedicacionLaboralId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    }

    const {
      fetchLaboralDedication,
      updateLaboralDedication,
      createLaboralDedication,
    } = humanResources()

    const resetLaboralDedication = () => {
      laboralDedication.value = JSON.parse(JSON.stringify(blankLaboralDedication))
    }

    if (router.currentRoute.params.dedicacionLaboralId) {
      fetchLaboralDedication(router.currentRoute.params.dedicacionLaboralId, data => {
        laboralDedication.value = data
      })
    } else {
      laboralDedication.value = JSON.parse(JSON.stringify(blankLaboralDedication))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetLaboralDedication)

    const onSubmit = () => {
      addingLaboralDedication.value = true
      if (laboralDedication.value.dedicacionLaboralId === 0) {
        createLaboralDedication(laboralDedication.value, () => {
          addingLaboralDedication.value = false
          if (props.modalCall) {
            emit('find-records')
          } else {
            router.push({ name: 'apps-human-resources-laboral-dedication-list' })
          }
        })
      } else {
        updateLaboralDedication(laboralDedication.value.dedicacionLaboralId, laboralDedication.value, () => {
          addingLaboralDedication.value = false
          router.push({ name: 'apps-human-resources-laboral-dedication-list' })
        })
      }
    }

    return {
      laboralDedication,
      addingLaboralDedication,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,

    }
  },
}
</script>
